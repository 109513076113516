@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

/* Ng Zorro */
// @import "../node_modules/ng-zorro-antd/ng-zorro-antd.min.css";

/* PrimeNG */
@import "../node_modules/primeng/resources/themes/lara-light-blue/theme.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

@import "../node_modules/angular-calendar/css/angular-calendar.css";

@import "../node_modules/ngx-toastr/toastr.css";

html {
  font-size: 14px;
}

:root body {
  font-family: "Inter var", sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  margin: 0px;
}

:root .p-dropdown {
  width: 100% !important;
}

:root {
  --surface-ground: #eff3f8 !important;
  --login-logo-ground: #bad4ff;
}

:root .cal-week-view .cal-time-events .cal-event {
  font-weight: bold;
}

:root .cal-week-view .cal-event {
  color: black;
}

:root .p-datatable .p-datatable-tbody>tr {
  color: #000000 !important;
}

:root .cal-week-view .cal-time-events {
  overflow-y: auto;
  max-height: 65vh;

}