// Here you can add other styles

// custom .chartjs-tooltip-body-item padding
@import "charts";

// custom tweaks for scrollbar styling (wip)
@import "scrollbar";

 // custom calendar today cell color
.calendar-cell.today {
  --cui-calendar-cell-today-color: var(--cui-info) !important;
}

// custom select week cursor pointer
.select-week .calendar-row.current {
  cursor: pointer;
}

.font-prime{
  font-family: "Inter var", sans-serif !important;
}
